<template>
  <div id="app">
    <!-- 登录 -->
    <div class="login_box">
      <Back></Back>
      <!-- 背景照片 -->
      <div class="login_content">
        <!-- logo -->
        <img class="logo" :src="this.url.imgUrl + logoImg" alt="" />
        <!-- 登录/注册按钮 -->
        <div class="login_register_btn">
          <div
            class="login"
            :class="{ choose_btn: loginTxt == true }"
            @click="showLogin"
          >
            登录
          </div>
          |
          <div
            class="register"
            :class="{ choose_btn: registerTxt == true }"
            @click="showRegister"
          >
            注册
          </div>
        </div>
        <!-- 登录内容 -->
        <div class="login_card" id="login">
          <!-- 输入框 -->
          <div class="login_ipt">
            <div class="ipt_left">手机号</div>
            <el-input
              class="mobile_ipt"
              v-model="form.mobile"
              placeholder=""
              maxlength="11"
            ></el-input>
            <!-- <div class="ipt_img"> -->
            <img class="user" src="../../assets/yonghu.png" alt="" />
            <!-- </div> -->
          </div>
          <div class="login_ipt">
            <div class="ipt_left">密&nbsp;&nbsp;&nbsp;码</div>
            <el-input
              class="mobile_ipt"
              v-model="form.password"
              placeholder=""
              :type="pwd_type"
            ></el-input>
            <!-- <div class="ipt_img"> -->
            <div class="pwd_img_box">
              <img class="pwd_eye" :src="eye_status" alt="" @click="showPwd" />
              <img class="pwd_suo" :src="status" alt="" @click="delectIpt" />
              <!-- <img class="pwd_suo" src="status" alt="" />
              <img class="pwd_suo" :src="status" alt="" @click="delectIpt" /> -->
            </div>

            <!-- </div> -->
          </div>
          <!-- 登录按钮 -->
          <div class="login_btn" @click="goLogin">登录</div>
          <!-- 忘记密码 -->
          <div class="forget_pwd_box">
            <p>忘记密码?</p>
            <router-link to="/forget">找回密码</router-link>
          </div>
        </div>
        <!-- 注册内容 -->
        <div class="register_box" id="register">
          <div class="register_card">
            <div class="register_ipt">
              <div class="ipt_left">手机号</div>
              <el-input
                class="mobile"
                v-model="form.mobile"
                placeholder=""
                maxlength="11"
              ></el-input>
            </div>
            <div class="register_ipt img_code_box">
              <div class="ipt_left">图形验证码</div>
              <el-input
                class="captcha"
                v-model="form.verification_code"
                placeholder=""
                maxlength="4"
              ></el-input>
              <!-- 图形验证码 -->
              <img
                class="img_code"
                :src="captchaImg"
                alt=""
                @click="getImgCode()"
              />
            </div>
            <div class="register_ipt message_box">
              <div class="ipt_left">短信验证码</div>
              <el-input
                class="message"
                v-model="form.code"
                placeholder=""
                maxlength="4"
              ></el-input>
              <div class="get_message" @click="sendCode">{{ btntxt }}</div>
            </div>
            <!-- <div class="register_ipt">
              <el-input
                class="pwd"
                v-model="form.password"
                placeholder="输入密码"
              ></el-input>
            </div> -->
            <div class="register_ipt">
              <div class="ipt_left select_ipt">请选择您的备考科目</div>
              <el-select v-model="form.subject_id" placeholder="">
                <el-option
                  v-for="item in options"
                  :key="item.subject_id"
                  :label="item.name"
                  :value="item.subject_id"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <!-- 用户协议/隐私政策 -->
          <div class="theaty_box">
            <img :src="radios" alt="" @click="chooseRadio" />
            我已阅读并同意
            <p>用户协议</p>
            和
            <p>隐私政策</p>
          </div>
          <!-- 立即注册 -->
          <div class="register_btn" @click="goRegister">立即注册</div>
          <!-- 忘记密码 -->
          <div class="forget_pwd_box">
            <p>忘记密码?</p>
            <router-link to="/forget">找回密码</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
import crypto from "crypto";
import qs from "qs";
import back from "../../components/back.vue";
// import { log } from "util";
export default {
  components: { back },
  data() {
    return {
      logoImg: "qinxue_login_logo.png",
      // showLogin: true,
      // showRegister: false,
      loginTxt: true,
      registerTxt: false,
      radio: false,
      form: {
        mobile: "", //手机号verification_code
        verification_code: "", // 图形验证码
        code: "", // 短信验证码
        password: "", //密码
        subject_id: "", // 科目id
        nonce_str: "", // 随机字符串
      },
      options: [], // 科目列表
      captchaImg: require("../../assets/tuxing.png"), // 图形验证码
      btntxt: "获取验证码", // 获取短信验证码,
      time: 60,
      status: require("../../assets/suo.png"), // 密码框输入状态
      eye_status: require("../../assets/eye_close.png"), // 密码显示状态图片
      pwd_type: "password", // 密码框type属性
      radios: require("../../assets/radio.png"), //隐私协议单选按钮
      choose_radio: false, // 选择协议
    };
  },
  watch: {
    // 监听密码输入,切换状态
    "form.password"() {
      // console.log("我被监听了");
      // if (this.status === require("../../assets/close.png")) {
      //   this.delectIpt();
      // }
      if (this.form.password.length > 0) {
        this.status = require("../../assets/close.png");
        document.querySelector(".pwd_eye").style.display = "block";
      } else {
        this.status = require("../../assets/suo.png");
        document.querySelector(".pwd_eye").style.display = "none";
      }
    },
  },
  // 方法集合
  methods: {
    // 显示登录
    showLogin() {
      var login = document.getElementById("login");
      var register = document.getElementById("register");
      login.style.display = "block";
      register.style.display = "none";
      this.registerTxt = false;
      this.loginTxt = true;
    },
    // 显示注册
    showRegister() {
      this.form.mobile = "";
      var login = document.getElementById("login");
      var register = document.getElementById("register");
      login.style.display = "none";
      register.style.display = "block";
      this.registerTxt = true;
      this.loginTxt = false;
    },
    // 密码显示状态
    showPwd() {
      this.pwd_type = this.pwd_type === "password" ? "text" : "password";
      this.eye_status =
        this.eye_status === require("../../assets/eye_close.png")
          ? require("../../assets/eye_open.png")
          : require("../../assets/eye_close.png");
    },
    // 删除输入框内容
    delectIpt() {
      // if (this.status == require("../../assets/suo.png")) {
      this.form.password = "";
      // }
    },
    // 点击切换图形验证码
    getImgCode() {
      var md5 = crypto.createHash("md5");
      md5.update(Math.ceil(Math.random() * 10) + "" + Date.now());
      this.form.nonce_str = md5.digest("hex");
      const reg = /^1[3-9][0-9]{9}$/;
      if (
        this.form.mobile == "" ||
        this.form.mobile <= 10 ||
        !reg.test(this.form.mobile)
      ) {
        this.$toast.fail("请输入正确手机号!");
        return false;
      } else {
        this.captchaImg =
          this.url.apiUrl +
          "/api/verification_code/verification?nonce_str=" +
          this.form.nonce_str +
          "&mobile=" +
          this.form.mobile +
          "&w=" +
          90 +
          "&h=" +
          36;
      }
    },
    // 发送短信验证码
    sendCode() {
      const reg = /^1[3-9][0-9]{9}$/;
      if (
        this.form.mobile == "" ||
        this.form.mobile <= 10 ||
        !reg.test(this.form.mobile)
      ) {
        this.$toast.fail({ duration: 1500, message: "请输入正确手机号!" });
        return false;
      } else if (this.form.verification_code == "") {
        this.$toast.fail({
          duration: 1500,
          message: "请输入正确图形验证码!",
        });
        return;
      } else {
        var data = {
          mobile: this.form.mobile,
          event: "register",
          verification_code: this.form.verification_code,
          nonce_str: this.form.nonce_str,
        };
        // this.qs.stringify(
        api.sms(data).then((res) => {
          if (parseInt(res.data.code) == 1) {
            // console.log(res);
            this.$toast.fail({ duration: 1500, message: res.data.msg });
            this.time = 60;
            this.timer();
          } else {
            this.$toast.fail({ duration: 1500, message: res.data.msg });
          }
        });
      }
    },
    //发送手机验证码倒计时
    timer() {
      if (this.time > 0) {
        this.disabled = true;
        this.time--;
        this.btntxt = this.time + "秒";
        setTimeout(this.timer, 1000);
      } else {
        this.time = 0;
        this.btntxt = "发送验证码";
        this.disabled = false;
      }
    },
    // 科目列表
    getSubject() {
      api.subject().then((res) => {
        this.options = res.data.data;
      });
    },
    // 选择协议
    chooseRadio() {
      this.choose_radio = !this.choose_radio;
      // console.log(this.choose_radio);
      if (this.choose_radio == true) {
        this.radios = require("../../assets/radio_choose.png");
      } else {
        this.radios = require("../../assets/radio.png");
      }
    },
    // 登录
    goLogin(id) {
      const reg = /^1[3-9][0-9]{9}$/;
      if (
        this.form.mobile == "" ||
        this.form.mobile <= 10 ||
        !reg.test(this.form.mobile)
      ) {
        this.$toast.fail({ duration: 1500, message: "请输入正确手机号!" });
        return false;
      } else if (this.form.password == null) {
        this.$toast.fail({ duration: 1500, message: "请输入正确的密码!" });
        return false;
      } else {
        var data = {
          account: this.form.mobile,
          password: this.form.password,
        };
        api.Login(qs.stringify(data)).then((res) => {
          // console.log(res);
          if (parseInt(res.data.code) == 1) {
            localStorage.setItem("token", res.data.data.token);
            this.$toast.success({
              duration: 1500,
              message: "登录成功!",
            });
            this.$router.push({ name: "Index" });
          } else {
            this.$toast.fail({
              duration: 1500,
              message: res.data.msg,
            });
          }
        });
      }

      // console.log(id);
      // this.showLogin == id;
      // console.log("登录", 1111);
    },
    // 注册
    goRegister() {
      const reg = /^1[3-9][0-9]{9}$/;
      if (
        this.form.mobile == "" ||
        this.form.mobile <= 10 ||
        !reg.test(this.form.mobile)
      ) {
        this.$toast.fail({ duration: 1500, message: "请输入正确手机号" });
        return false;
      } else if (this.form.verification_code == "") {
        this.$toast.fail({ duration: 1500, message: "请输入正确图形验证码" });
        return false;
      } else if (this.form.code == "") {
        this.$toast.fail({ duration: 1500, message: "请输入正确短信验证码" });
        return false;
      }
      // else if (this.form.password == null) {
      //   this.$toast.fail({ duration: 1500, message: "请输入正确密码" });
      //   return false;
      // }
      else if (this.form.subject_id == "") {
        this.$toast.fail({ duration: 1500, message: "请选择备考科目" });
        return false;
      } else if (this.choose_radio == false) {
        this.$toast.fail({ duration: 1500, message: "请勾选协议!" });
        return false;
      } else {
        var data = {
          mobile: this.form.mobile, // 手机号
          password: "123456",
          code: this.form.code, // 验证码
          subject_id: this.form.subject_id, // 科目id
        };
        api.register(qs.stringify(data)).then((res) => {
          // console.log("注册", res);
          if (parseInt(res.data.code) == 1) {
            this.$toast.success({
              duration: 1500,
              message: "注册成功!",
            });
            this.form = {};
          } else {
            this.$toast.fail({
              duration: 1500,
              message: res.data.msg,
            });
            this.form = {};
          }
        });
      }
      // this.showRegister == true;
      // this.showLogin == false;
      // console.log("注册", 222);
    },
  },
  beforeCreate() {
    if (
      navigator.userAgent.match(
        /(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i
      )
    ) {
      // document.write("手机访问.");
      // window.location.href = url.wapUrl
    } else {
      // document.write("电脑访问.");
      // window.location.href = "http://www.baidu.com"
      window.location.href = this.url.wwwUrl;
    }
  },

  created() {
    // this.iptStatus();
    this.getSubject();
  },
};
</script>

<style scoped lang="scss">
.login_box {
  width: 100%;
  height: 100vh;
  background-image: url("../../assets/background.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  /deep/#back_img {
    margin-left: 1.13rem;
    margin-top: 0.63rem;
  }
  .login_content {
    width: 18.75rem;
    display: flex;
    flex-flow: column;
    align-items: center;
    margin: auto;
    .logo {
      width: 6rem;
      height: 6rem;
      // margin-top: 3.94rem;
      margin: 3.94rem 0 2.5rem;
    }
    // 登录/注册按钮
    .login_register_btn {
      width: 7.81rem;
      display: flex;
      justify-content: space-around;
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 28px;
      margin-bottom: 1.5rem;
      .choose_btn {
        font-weight: 600;
        color: #3e7eff;
      }
    }
    .none {
      display: none;
    }
    // 登录内容
    .login_card {
      width: 100%;
      padding: 0 1rem;
      .login_ipt:nth-child(1) {
        margin-bottom: 0.94rem;
      }
      .login_ipt {
        // padding-right: 1rem;
        background: #fff;
        border-radius: 0.25rem;
        display: flex;
        align-items: center;
        .ipt_left {
          min-width: 42px;
          // background: pink;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #333333;
          margin-left: 1rem;
          margin-right: 0.81rem;
        }
        // 输入框
        /deep/.el-input__inner {
          // max-width: 10.31rem;
          height: 3.13rem;
          border: none;
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #333333;
          padding: 0;
          // background: skyblue;
        }
        .user {
          width: 1.13rem;
          height: 1.13rem;
          margin-right: 1rem;
        }
        .pwd_img_box {
          // width: 3rem;
          display: flex;
          // justify-content: flex-end;
          margin-right: 1rem;
          // background: pink;
          .pwd_eye {
            width: 1.25rem;
            height: 1.25rem;
            margin-right: 0.75rem;
            display: none;
          }
          .pwd_suo {
            width: 1.13rem;
            height: 1.13rem;
          }
        }
        // .ipt_img {
        //   min-width: 3.13rem;
        //   display: flex;
        //   // justify-content: flex-end;
        //   margin-right: 1rem;
        //   // background: green;
        //   img {
        //     width: 1.13rem;
        //     height: 1.13rem;
        //   }
        //   img:nth-child(1) {
        //     margin-right: 0.63rem;
        //   }
        // }
      }

      .login_btn {
        // width: 300px;
        width: 100%;
        height: 2.75rem;
        background: linear-gradient(270deg, #aec7fb 0%, #3e7eff 100%);
        border-radius: 0.25rem;
        font-size: 1rem;
        font-family: PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 2.75rem;
        text-align: center;
        margin: 1.88rem 0 0.88rem;
      }
    }
    // 忘记密码
    .forget_pwd_box {
      width: 100%;
      display: flex;
      justify-content: center;
      font-size: 13px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
      p {
        margin-right: 0.31rem;
      }
      a {
        color: #4285f5;
      }
    }
    // 注册内容
    .register_box {
      width: 100%;
      height: 100px;
      display: none;
      .register_card {
        width: 100%;
        // height: 15.63rem;
        background: #fff;
        border-radius: 4px;
        .register_ipt {
          display: flex;
          align-items: center;
          // width: 16.75rem;
          padding: 0 16px;
          // padding-right: 1rem;
          .ipt_left {
            min-width: 4.38rem;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #333333;
          }
          /deep/.el-input__inner {
            width: 100%;
            height: 3.13rem;
            border: none;
            padding: 0 16px;
            // text-align: center;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #333333;
            // padding-right: 1rem;
          }
          /deep/.el-input__inner::placeholder {
            text-align: left;
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
          }
          /deep/.mobile .el-input__inner {
            background: url("../../assets/yonghu.png") right no-repeat;
            background-size: 1.25rem;
          }
          /deep/.pwd .el-input__inner {
            background: url("../../assets/suo.png") right no-repeat;
            background-size: 1.25rem;
          }
          .select_ipt {
            min-width: 7.88rem;
          }
        }

        // 图形验证码
        .img_code_box,
        .message_box {
          position: relative;
          display: flex;
          align-items: center;
        }
        .img_code {
          position: absolute;
          right: 16px;
        }
        // 短信验证码
        .get_message {
          min-width: 5.63rem;
          height: 2.25rem;
          background: #e2ecff;
          border-radius: 0.25rem;
          font-size: 0.88rem;
          font-family: PingFang SC;
          font-weight: 400;
          color: #3e7eff;
          line-height: 2.25rem;
          text-align: center;
        }
      }
      // 协议
      .theaty_box {
        display: flex;
        align-items: center;
        font-size: 13px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #999999;
        margin: 0.88rem 0 1.25rem;
        img {
          width: 1.13rem;
          height: 1.13rem;
        }
        p {
          color: #3e7eff;
        }
      }
      // 立即注册
      .register_btn {
        width: 300px;
        height: 44px;
        background: linear-gradient(270deg, #aec7fb 0%, #3e7eff 100%);
        border-radius: 4px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 44px;
        text-align: center;
        margin-bottom: 0.88rem;
      }
    }
  }
}
</style>
